.colorWhite {
  color: white !important;
  background-color: #ff5269;
  width: 100%;
  height: 65px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 1%;
  border-radius: 7px;
  margin-top: 20%;
  margin-left: 0%;
}

.colorWhite .a {
  color: white !important;
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  min-height: 100vh !important;
  z-index: 100;
  padding: 48px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  background-color: white;
}

.dropd {
  background-color: white;
  width: 100%;
  height: 65px;
  color: black;
  border-width: thin;
  border-radius: 7px;
  margin-top: 1%;
  margin-left: 10%;
}

.dd {
  border: 3px solid transparent;
  border-image: linear-gradient(to right, white, #f7526a);
  border-image-slice: 1;
}

#nav-dropdown-menu {
  padding-top: 8%;
  height: 100%;
  width: 17%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -100;
  overflow-x: hidden;
}

#nav-dropdown {
  color: #a4a6b3;
  font-weight: bold;
}

#sidebar-wrapper {
  min-height: 100vh !important;
  width: 100vw;
  margin-left: -1rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#page-content-wrapper {
  min-width: 0;
  width: 100%;
}
